/*md
@no-stat

# Force repaint

This mixin is useful when repaint needs to be triggered. (sometimes on iOS)

## Usage

```scss
.component {
	@include force_repaint;
}
```
*/

@keyframes repaint {
	from {
		padding-bottom: 0.01px;
	}

	to {
		padding-bottom: 0;
	}
}

@mixin force_repaint {
	animation: repaint 0.1s ease;
}
