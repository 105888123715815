/*md

# Link type 1

Fully inherits `t-link_underlined` component
with some modifications.

It takes the same list of arguments as `t-link_underlined`
component

```scss_example
.b-some_link {
	@include t-link_1;
}
```
*/

@mixin t-link_1($args...) {
	@include t-link_underlined($args..., $font-weight: 600, $underline-offset: 7px);
}
