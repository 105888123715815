/*md

# Paragraph components

```scss_example
.b-cart_empty {
	// ...

	&-paragraph {
		@include t-paragraph_1;
	}
}
```

*/

@mixin t-paragraph_1 {
	font: 18px/26px $font-main;
	letter-spacing: 0.2px;

	@content;
}

@mixin t-paragraph_2 {
	font: 16px/1.5 $font-main;
	letter-spacing: 0.2px;

	@content;
}

@mixin t-paragraph_3 {
	font: 14px/1.57 $font-main;
	letter-spacing: 0.2px;

	@content;
}

@mixin t-paragraph_4 {
	font: 15px/1.57 $font-main;
	letter-spacing: 0.2px;

	@content;
}
