@import 'sizes_tools';

$size-font: 14px;
$size-font-alt: 16px;
$size-line: 1.5;
$size-rhythm-step: 5; // setting for rh() function
$size-input-height: 46px; // https://adrianroselli.com/2019/06/target-size-and-2-5-5.html
$size-input-height-mobile: 56px;
$size-font-sub-text: 12px;

// Progress bar
$size-progress-width: 345px;
$size-progress-height: 5px;
$size-progress-radius: 2px;

//PLP
$size-product_tile-max_width: 330px;
$size-product_tile-wide-max_width: 900px;

// Buttons
$size-button-height: 60px;
$size-button-height-small: 40px;

//Search
$size-search_panel-content-max_width: 914px;
