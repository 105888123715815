.b-error_page {
	$_content-width: 340px;
	$_404-background_image: $configuration-error_page__404-background_image;
	$_404__mobile-background_image: $configuration-error_page__404__mobile-background_image;

	color: $color-error_410-text;
	display: grid;
	grid-auto-rows: min-content;
	padding: rh(16 0);
	text-align: center;

	@include media(sm) {
		padding: rh(10 0);
	}

	&-logo {
		margin: rh(10) auto rh(17);

		@include media(sm) {
			margin: rh(10) auto rh(15);
		}
	}

	&-title {
		@include t-heading_1;

		margin: 0 auto rh(8);
		max-width: 680px;

		@include media(md) {
			max-width: 60%;
		}

		@include media(sm) {
			margin: 0 auto rh(7);
			max-width: 100%;
		}
	}

	&-text,
	&-info {
		margin: 0 auto rh(4);
		max-width: $_content-width;

		@include media(sm) {
			max-width: 100%;
		}
	}

	&-text {
		@include t-paragraph_2;
	}

	&-info {
		@include t-paragraph_3;
	}

	&-button {
		@include g-button(main);

		margin: 0 auto rh(12);
		width: $_content-width;

		@include media(sm) {
			margin: 0 auto rh(10);
			width: 100%;
		}
	}

	&.m-server_error,
	&.m-not_found,
	&.m-offline {
		background-size: cover;
		color: $color-error_404-text;
		min-height: 100vh;
		padding: 0 grid-margin(sm) rh(10);
		width: 100%;

		@include media(sm) {
			height: auto;
		}
	}

	&.m-offline {
		background: $color-error_page-background url('../images/maintenance.jpg') no-repeat center center / cover;

		@include media(sm) {
			background-image: url('../images/maintenance-mobile.jpg');
		}
	}

	&.m-offline &-text {
		margin-bottom: rh(12);
	}

	&.m-offline &-text,
	&.m-offline &-info {
		max-width: 450px;
	}

	&.m-not_found {
		background: $color-error_page-background $_404-background_image no-repeat center center / cover;

		@include media(sm) {
			background-image: $_404__mobile-background_image;
		}
	}

	&.m-server_error {
		background: $color-error_page-background url('../images/internal-sever-error.jpg') no-repeat center center / cover;

		@include media(sm) {
			background-image: url('../images/internal-sever-error-mobile.jpg');
		}
	}

	&.m-not_found &-main,
	&.m-server_error &-main {
		color: $color-error_page-text;
		text-align: center;
	}

	&.m-not_found &-button,
	&.m-server_error &-button {
		@include g-button(alt);

		&.m-inversed {
			@include g-button(alt, inversed);
		}
	}
}
