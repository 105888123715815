/*md

# g-button_*

# Mixin for button global styles.

```scss_example
.b-button {
	@include g-button_global($_mod: default);
}
```

*/

@mixin g-button_global($_mod: default) {
	@if $_mod == default {
		@include g-button_reset;

		font-family: $font-main;
		font-size: $configuration-button-font_size;
		font-weight: $configuration-button-font_weight;
		height: $size-button-height;
		letter-spacing: $configuration-button-letter_spacing;
		line-height: 1.2;
		padding: 5px 25px; // 5px support multiline on IOs
		transition: $motion-ease;
		transition-property: background-color, color, border;

		&:hover {
			text-decoration: none;
		}
	}

	@if $_mod == disabled {
		@include g-button_reset(disabled);

		@include media(md-down) {
			padding: rh(1 0);
		}
	}

	@if $_mod == small {
		font-weight: $configuration-button__small-font_weight;
		height: $size-button-height-small;
		padding: 8px 15px;
	}

	@if $_mod == full_width {
		width: 100%;
	}

	@if $_mod == width_s {
		width: var(--button-s);
	}

	@if $_mod == width_m {
		width: var(--button-m);
	}

	@if $_mod == width_l {
		width: var(--button-l);
	}
}
