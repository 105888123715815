/*md

# Headings components

```scss_example
.b-title {
	@include t-heading_1;
}
```

*/

@mixin t-heading_1 {
	font: $configuration-t_heading_1-font;
	letter-spacing: 0.6px;

	@include media(md-down) {
		font-size: 40px;
		letter-spacing: 0.5px;
		line-height: 48px;
	}

	@content;
}

@mixin t-heading_2 {
	font: $configuration-t_heading_2-font;
	letter-spacing: 0.5px;

	@include media(md-down) {
		font-size: 32px;
		letter-spacing: 0.4px;
		line-height: 40px;
	}

	@content;
}

@mixin t-heading_3 {
	font: $configuration-t_heading_3-font;
	letter-spacing: 0.4px;

	@include media(md-down) {
		font-size: 26px;
		letter-spacing: 0.325px;
		line-height: 32px;
	}

	@content;
}

@mixin t-heading_4 {
	font: $configuration-t_heading_4-font;
	letter-spacing: 0.35px;

	@include media(md-down) {
		font-size: 24px;
		letter-spacing: 0.3px;
	}

	@content;
}

@mixin t-heading_5 {
	font: $configuration-t_heading_5-font;
	letter-spacing: 0.3px;

	@content;
}

@mixin t-heading_6 {
	font: $configuration-t_heading_6-font;
	letter-spacing: 2.4px;
	text-transform: uppercase;

	@content;
}
