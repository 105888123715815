/*md

# Link type 3

Link followed with the arrow.
It extends `t-link` component

```scss_example
.b-some_link {
	@include t-link_3;
}
```
*/

@keyframes link-arrow {
	0% {
		width: 5px;
	}

	100% {
		width: 50px;
	}
}

@mixin t-link_3 {
	@include t-link(visited);

	@include t-link(default, $font-weight: bold) {
		font-size: 16px;
		letter-spacing: 2.4px;
		line-height: 24px;
		text-transform: uppercase;
	}

	@include icon(line-arrow, $icon-width: rh(10), $icon-height: 13px, $position: after) {
		display: inline-block;
	}

	@include t-link(hover) {
		&::after {
			animation: link-arrow 0.4s ease;
		}
	}
}
