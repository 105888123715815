// # Buttons
$configuration-button-font_size: 14px !default;
$configuration-button-font_weight: 800 !default;
$configuration-button__small-font_weight: 700 !default;
$configuration-button_main-border_width: 2px !default;
$configuration-button_main__disabled-opacity: 0.2 !default;
$configuration-button-letter_spacing: 3px !default;

// # Links
$configuration-link_2-underline_offset: 3px !default;

// # Form
$configuration-form-letter_spacing: 2.1px !default;
$configuration-form-select_transform: capitalize !default;
$configuration-form-input-font: 700 14px/22px $font-main !default;
$configuration-form-select-font: 700 12px/22px $font-main !default;

// # Product tile
$configuration-product_tile-brand-line_height: 1.8 !default;
$configuration-product_tile-brand-font_size: 12px !default;
$configuration-product_tile-brand-font_weight: 700 !default;
$configuration-product_tile-brand-letter_spacing: 1px !default;
$configuration-product_tile-brand-font_size__small: 11px !default;
$configuration-product_tile-name-font: 700 16px $font-main !default;
$configuration-product_tile-name-font_size__small: 14px !default;
$configuration-product_tile-name-letter_spacing: 2.4px !default;
$configuration-product_tile-name-transform: uppercase !default;
$configuration-product_tile-description-font_weight: 600 !default;
$configuration-product_tile-cta_type: main !default;
$configuration-product_tile-cta_modification: default !default;

// # Product price
$configuration-product_price-font: 700 16px/20px $font-main !default;
$configuration-product_tile-description-font_weight: bold !default;
$configuration-product_tile-description-letter_spacing: 0.5px !default;

// # Product quantity stepper
$configuration-product_quantity-border_width: 1px !default;
$configuration-product_quantity-disabled-opacity: 0.4 !default;

// # Product badge
$configuration-product_badge-points-line_height: 1.1 !default;
$configuration-product_badge-border_radius: 3px !default;
$configuration-product_badge-font: 700 14px/24px $font-main !default;
$configuration-product_badge-font_size__small: 11px !default;

// # Product details
$configuration-product_details-brand-font: 700 16px/1.5 $font-main !default;
$configuration-product_details-brand-letter_spacing: 2.4px;
$configuration-product_details-price-font_size: 22px !default;

// # Promo
$configuration-promo-border_radius: 3px !default;

// # PLP & SEARCH
$configuration-filter_selected-border_radius: 0 !default;
$configuration-load_progress-font: 14px !default;
$configuration-load_progress-transform: uppercase !default;
$configuration-search_input-placeholder-font_weight: 400 !default;
$configuration-noof-grid-template-columns: 3 !default;
$configuration-no-filter-grid-template-columns: 4;

// # Age gate
$configuration-age_gate-background_image: url('../images/age-gate-background.jpg') !default;
$configuration-age_gate__mobile-background_image: url('../images/age-gate-background-mobile.jpg') !default;
$configuration-age_gate-button_type: alt !default;
$configuration-age_gate-button_modification: default inversed !default;

// # Global label
$configuration-global_label-font: 600 12px/16px $font-main !default;
$configuration-global_label-letter_spacing: 1.8px !default;
$configuration-global_label-text_transform: uppercase !default;

// # Global header
$configuration-header-offset_top: 35px !default;

// ## Logo
$configuration-logo-width: 255px !default;
$configuration-logo-width__md_down: 146px !default;
$configuration-logo__stuck-width: 182px !default;
$configuration-logo__stuck-width__md_down: $configuration-logo-width__md_down !default;
$configuration-logo__portrait-width: 80px !default;
$configuration-logo__stuck__portrait-width: 80px !default;
// ### Logo aspect ratio variables. They have to be an integer
$configuration-logo-image-height: 29 !default;
$configuration-logo-image-width: 255 !default;
$configuration-logo-image__portrait-height: 151 !default;
$configuration-logo-image__portrait-width: 234 !default;
$configuration-logo__stuck-image__portrait-height: 90 !default;// reduce the height to hide the bottom of a logo

// ## Global header Navigation
$configuration-category_navigation-list_1-padding: 30px 0 0 !default;
$configuration-category_navigation-link-font: 700 14px/24px $font-main !default;
$configuration-category_navigation-link-letter_spacing: 3px !default;
$configuration-category_navigation-link-text_tranform: uppercase !default;

// # Headings
$configuration-t_heading_1-font: 400 48px/1.2 $font-alt !default;
$configuration-t_heading_2-font: 400 40px/48px $font-alt !default;
$configuration-t_heading_3-font: 400 32px/40px $font-alt !default;
$configuration-t_heading_4-font: 400 28px/36px $font-main !default;
$configuration-t_heading_5-font: 700 22px/36px $font-main !default;
$configuration-t_heading_6-font: 700 16px/24px $font-main !default;

// # Promotion
$configuration-product_promo-link-font_size: 16px !default;
$configuration-product_promo-link-font_weight: 400 !default;

// # Checkout
// ## Confirmation page
$configuration-confirmation-account-background_image: url('./images/confirmation-account_creation--md_up.jpg') !default;
$configuration-confirmation-account-background_image__small: url('./images/confirmation-account_creation--sm.jpg') !default;
$configuration-confirmation_create_account-button_modifications: inversed !default;

// # Cart product details
$configuration-cart_product_details-name-font: $configuration-t_heading_6-font !default;
$configuration-cart_product_details-name-letter_spacing: 2.4px !default;
$configuration-cart_product_details-name-transform: uppercase !default;

// # Minicart product
$configuration-minicart_product-name-font_size: 14px !default;

// # Highlighter component
$configuration-highlighter-outline_width: 3px !default;

// # Error pages
$configuration-error_page__404-background_image: url('../images/404-background.jpg') !default;
$configuration-error_page__404__mobile-background_image: url('../images/404-background-mobile.jpg') !default;

// # Horizontal Filter
$configuration-horizontal-filter-title-font-size: 12px;
$configuration-horizontal-filter-font-weight: 600;
$configuration-horizontal-filter-selected-item-font-size: 16px;
