/*md

# Core component for all links

All core and common links styling
stores in this mixin

Be careful changing styles in this mixin!

```scss_example
@mixin t-link_6 {
	@include t-link;
}
```

*/

@mixin t-link(
	$state: all,
	$color: inherit,
	$color-disabled: $color-link__disabled,
	$color-visited: $color-link__visited,
	$font-weight: normal
) {
	@if $state == all {
		@include t-link($state: default, $color: $color, $font-weight: $font-weight);
		@include t-link($state: visited, $color-visited: $color-visited); // inherit?
		@include t-link($state: hover);

		@content;

		&.m-disabled {
			@include t-link($state: disabled);
		}
	}

	@if $state == default {
		color: var(--color-link, $color);
		cursor: pointer;
		display: inline;
		font-weight: $font-weight;
		letter-spacing: 0.16px;

		@content;
	}

	@if $state == visited {
		&:visited {
			color: var(--color-link-visited, $color-visited);

			@content;
		}
	}

	@if $state == hover {
		&:hover {
			text-decoration: none;

			@content;
		}
	}

	@if $state == disabled {
		color: var(--color-link-disabled, $color-disabled);
		pointer-events: none;

		@content;
	}
}
