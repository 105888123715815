// # Header
// ## Logo
$configuration-logo-width: 356px;
$configuration-logo-width__md_down: 146px;
$configuration-logo__stuck-width: 208px;
$configuration-logo__portrait-width: 42px;
$configuration-logo__stuck__portrait-width: 42px;
// ### Logo aspect ratio variables. They have to be an integer
$configuration-logo-image-height: 85;
$configuration-logo-image-width: 190;
$configuration-logo-image__portrait-height: 91;
$configuration-logo-image__portrait-width: 98;
$configuration-logo__stuck-image__portrait-height: 91;// reduce the height to hide the bottom of a logo

// # Buttons
$configuration-button-font_size: 16px;
$configuration-button-font_weight: 900;
$configuration-button-letter_spacing: 1px;

// # Links
$configuration-link_2-underline_offset: 0;

// # Form
$configuration-form-letter_spacing: 1px;
$configuration-form-select_transform: none;
$configuration-form-input-font: 600 16px/22px $font-main;
$configuration-form-select-font: $configuration-form-input-font;

// # Product tile
$configuration-product_tile-brand-font_size: 14px;
$configuration-product_tile-brand-font_weight: 600;
$configuration-product_tile-brand-font_size__small: 12px;
$configuration-product_tile-brand-letter_spacing: 1.5px;
$configuration-product_tile-name-font: 600 20px/32px $font-alt;
$configuration-product_tile-name-font_size__small: 16px;
$configuration-product_tile-name-letter_spacing: 0.5px;
$configuration-product_tile-name-transform: none;
$configuration-product_tile-description-font_weight: 400;

// # Product price
$configuration-product_price-font: 600 20px/32px $font-main;

// # Product badge
$configuration-product_badge-points-line_height: 0.8;
$configuration-product_badge-font: 600 14px/24px $font-main;

// # Product details
$configuration-product_details-price-font_size: 20px;

// # Age gate
$configuration-age_gate-background_image: url('../images/age-gate-background.jpg');
$configuration-age_gate__mobile-background_image: url('../images/age-gate-background.jpg');
$configuration-age_gate-button_type: main;
$configuration-age_gate-button_modification: default;

// # Global label
$configuration-global_label-font: 600 16px/24px $font-main;
$configuration-global_label-letter_spacing: 0.3px;
$configuration-global_label-text_transform: none;

// # Global header
$configuration-header-offset_top: 25px;

// ## Global header Navigation
$configuration-category_navigation-list_1-padding: 16px 0 0;
$configuration-category_navigation-link-font: 700 16px/24px $font-alt;
$configuration-category_navigation-link-letter_spacing: 0.3px;
$configuration-category_navigation-link-text_tranform: none;

// # Headings
$configuration-t_heading_1-font: 700 48px/56px $font-alt;
$configuration-t_heading_2-font: 700 36px/48px $font-alt;
$configuration-t_heading_3-font: 700 28px/40px $font-alt;
$configuration-t_heading_4-font: 400 28px/36px $font-main !default;
$configuration-t_heading_5-font: 700 16px/24px $font-main !default;
$configuration-t_heading_6-font: 700 16px/24px $font-main !default;

// Promotion
$configuration-product_promo-link-font_weight: 700;

// # PLP & SEARCH
//$configuration-filter_selected-border_radius: 3px;
$configuration-load_progress-font: 16px;
$configuration-load_progress-transform: none;
$configuration-search_input-placeholder-font_weight: 700;
$configuration-noof-grid-template-columns:3;

// # Product details
$configuration-product_details-brand-font: 700 18px/1.5 $font-main;
$configuration-product_details-brand-letter_spacing: 1.5px;

// # Error pages
$configuration-error_page__404-background_image: url('../images/age-gate-background.jpg');
$configuration-error_page__404__mobile-background_image: url('../images/age-gate-background.jpg');
