.l-error_page {
	@include g-section_holder;

	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	padding: rh(12 0 20 0);

	&-title {
		@include t-heading_4;

		margin-bottom: rh(5);
	}

	&-link {
		@include t-link_1($color-visited: $color-text);

		font-size: 16px;
		font-weight: 600;
	}

	&-content.m-center {
		text-align: center;
	}
}
