/*md
@no-stat

# Rh (Indents rhythm)

Initial assumption is that Designs should follow the agreed Rhythm.

The main puprose of Rhythm function is to soothe inconsistencies of design for vertical and horizontal indents if such appear.

If we have agreed on Rhythm = 5 and we meet padding or margin 22px in design - we should round it to rh(4)=20px

Rhythm function can be avoided if some measurements are specific, i.e.:
	-icon size, blocks width/height are specific values
	-form elements paddings/margins/sizes are usually specific values
	-positioning of elements might have nothing to do with parent paddings

## Usage

```scss
.component {
	@include rh(2); // => 10px
	@include rh(2 4 0); // => 10px 20px 0
}
```
*/

/* stylelint-disable */
@function rh($parameter) {
	$step: $size-rhythm-step;
	$result: null;

	@if (type-of($parameter) == list) {
		@for $i from 1 through length($parameter) {
			$item: nth($parameter, $i);
			$el: 0;

			@if $item != 0 {
				$el: nth($parameter, $i) * $step * 1px;
			}

			$result: join($result, $el);
		}
	} @else {
		$result: $parameter * $step * 1px;
	}

	@return $result;
}
/* stylelint-enable */
