// stylelint-disable

// # Source Sans Pro

// ## Regular

// ### latin-ext
@import url("https://use.typekit.net/lju5iea.css");
@import url("https://use.typekit.net/vdo5edq.css");

//New Fonts
$font-clarendon:'clarendon-urw', serif;
$font-baskervilleurw: 'baskerville-urw', serif;

$font-main: $font-baskervilleurw;
$font-alt: $font-clarendon;


