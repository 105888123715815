/*md

# Underlined link component

It extends and modifies `t-link` component
and is a core component for underlined links

```scss_example
@mixin t-link_1 {
	@include t-link_underlined;

	...
}
```
*/

@keyframes link-underline {
	0% {
		background-size: 0 1px;
	}

	100% {
		background-size: 100% 1px;
	}
}

@mixin t-link_underlined(
	$state: all,
	$color: inherit,
	$color-disabled: $color-link__disabled,
	$color-visited: $color-link__visited,
	$font-weight: normal,
	$underline-offset: 0
) {
	@if $state == all {
		@include t-link_underlined($state: default, $color: $color, $font-weight: $font-weight, $underline-offset: $underline-offset);
		@include t-link_underlined($state: visited, $color-visited: $color-visited);
		@include t-link_underlined($state: hover);

		@content;

		&.m-disabled {
			@include t-link_underlined($state: disabled, $color-disabled: $color-disabled);
		}
	}

	@if $state == default {
		@include t-link($color: $color, $font-weight: $font-weight);

		background-image: linear-gradient(currentColor, currentColor);
		background-position: 0 90%;
		background-repeat: no-repeat;
		background-size: 100% 1px;
		padding-bottom: $underline-offset;

		@content;
	}

	@if $state == hover {
		@include t-link($state: hover) {
			@content;

			@media (hover: hover) and (pointer: fine) {
				animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards;
			}
		}
	}

	@if $state == visited {
		@include t-link($state: visited, $color-visited: $color-visited) {
			@content;
		}
	}

	@if $state == disabled {
		@include t-link($state: disabled, $color-disabled: $color-disabled) {
			@content;
		}
	}
}
