/*md

# g-button_reset*

Mixin for reset button style.

```scss_example
.b-button {
	@include g-button_reset;
}

```

*/

@mixin g-button_reset(
	$_mod: default,
) {
	@if $_mod == default {
		align-items: center;
		background: none;
		border: 0;
		cursor: pointer;
		display: inline-flex;
		justify-content: center;
		max-width: 100%;
		text-align: center;
		text-transform: uppercase;
		user-select: none;
		vertical-align: top;
		word-break: break-word;
	}

	@if $_mod == disabled {
		pointer-events: none;
	}
}
