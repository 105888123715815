// common-***.css and page-specific CSS files
// are loaded asynchronously, so we should make
// sure page will be visible only after
// common-***.css is loaded.
// that's why CSS custom properties are used
// for opacity and visibility properties
// --page_opacity and --page_visibility have
// to be declared in common-***.scss file
body {
	opacity: var(--page_opacity, 0);
	overflow: auto;
	overflow-y: scroll;
	pointer-events: all;
	visibility: var(--page_visibility, hidden);
}
